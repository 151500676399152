<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    variant?: string
  }>(),
  {
    variant: 'dark',
  }
)
</script>

<template>
  <article class="flex min-h-full flex-col">
    <figure
      class="relative m-0 block w-full overflow-hidden p-0 before:block before:pt-[100%] before:content-['']"
    >
      <div
        class="absolute bottom-0 left-0 top-0 h-full w-full border-0 bg-neutral-200 object-cover"
      ></div>
    </figure>
    <div
      class="flex grow flex-col justify-between"
      :class="
        props.variant === 'dark'
          ? ['bg-black', 'text-white', 'p-10']
          : ['bg-white', 'text-black', 'py-10']
      "
    >
      <div class="mb-4">
        <UiSkeletonText
          class="mb-6 mr-auto h-4 w-20 max-w-full"
        ></UiSkeletonText>
        <UiSkeletonText class="mb-4"></UiSkeletonText>
        <UiSkeletonText class="h-32"></UiSkeletonText>
      </div>
      <UiSkeletonText class="mr-auto h-4 w-20 max-w-full"></UiSkeletonText>
    </div>
  </article>
</template>
