<script setup lang="ts">
const localePath = useLocalePath()

const { data: newsData, status } = await useLazyAsyncData(() =>
  createNewsRepository().getAllPagination(1, 10)
)
</script>

<template>
  <section class="pt-16">
    <div class="container">
      <div v-if="status === 'pending'">
        <UiSkeletonLatestNews />
      </div>
      <div v-else-if="newsData?.items && newsData?.items.length">
        <TypographyH2Homepage class="mb-8">
          {{ $t('homepage.news.title') }}
        </TypographyH2Homepage>
        <div class="-mb-64 flex justify-between gap-10 pb-48 max-lg:flex-col">
          <div class="w-full max-w-2xl">
            <div class="aspect-[3/2] w-full">
              <NuxtLink
                :to="
                  localePath({
                    name: 'news-slug',
                    params: { slug: newsData.items[0].urlSlug },
                  })
                "
              >
                <img
                  class="h-full w-full object-cover"
                  :src="withHost(newsData.items[0].thumbnailImage?.url)"
                />
              </NuxtLink>
            </div>
          </div>
          <div class="flex flex-col justify-between gap-8">
            <div class="flex flex-col gap-8">
              <time class="block text-sm">
                {{
                  new Intl.DateTimeFormat('cs-CZ', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  }).format(new Date(newsData.items[0].releaseDate))
                }}
              </time>
              <NuxtLink
                :to="
                  localePath({
                    name: 'news-slug',
                    params: { slug: newsData.items[0].urlSlug },
                  })
                "
                class="no-underline"
              >
                <h2 class="font-pragmatica-ext text-4xl font-bold">
                  {{ newsData.items[0].title }}
                </h2>
              </NuxtLink>
              <p class="lg:max-w-md">
                {{ truncate(stripHtml(newsData.items[0].content ?? ''), 250) }}
              </p>
            </div>
            <NuxtLink
              :to="
                localePath({
                  name: 'news-slug',
                  params: { slug: newsData.items[0].urlSlug },
                })
              "
            >
              <UiButtonBlack>
                {{ $t('homepage.news.cta') }}
              </UiButtonBlack>
            </NuxtLink>
          </div>
        </div>
      </div>
    </div>
    <div class="overflow-hidden bg-neutral-100 pb-8 pt-48">
      <div class="container">
        <div class="block">
          <div class="flex gap-16 max-lg:flex-col lg:gap-4">
            <template v-if="status === 'pending'">
              <div class="basis-auto" v-for="i in 3" :key="i">
                <UiSkeletonReferencesCard dark />
              </div>
            </template>
            <template v-else>
              <div
                class="basis-auto"
                v-for="newsItem in newsData?.items.slice(1, 4)"
                :key="newsItem.nid"
              >
                <div class="mb-5 aspect-[3/2] w-full">
                  <NuxtLink
                    :to="
                      localePath({
                        name: 'news-slug',
                        params: { slug: newsItem.urlSlug },
                      })
                    "
                  >
                    <img
                      class="h-full w-full object-cover"
                      :src="withHost(newsItem.thumbnailImage?.url)"
                    />
                  </NuxtLink>
                </div>
                <div>
                  <NuxtLink
                    :to="
                      localePath({
                        name: 'news-slug',
                        params: { slug: newsItem.urlSlug },
                      })
                    "
                    class="block w-fit text-sm no-underline first-letter:uppercase"
                  >
                    <h2
                      class="font-pragmatica-ext mb-2.5 text-ellipsis text-2xl font-bold"
                    >
                      {{ newsItem.title }}
                    </h2>
                  </NuxtLink>
                  <div class="flex gap-4 text-sm">
                    <NuxtLink
                      :to="
                        localePath({
                          name: 'news-slug',
                          params: { slug: newsItem.urlSlug },
                        })
                      "
                      class="block w-fit text-sm no-underline first-letter:uppercase"
                    >
                      <UiAnimatedUnderlineText>
                        {{ $t('homepage.news.cta') }}
                      </UiAnimatedUnderlineText>
                    </NuxtLink>
                    <time>
                      {{
                        new Intl.DateTimeFormat('cs-CZ', {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                        }).format(new Date(newsItem.releaseDate))
                      }}
                    </time>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="mx-auto mt-8 block w-fit">
          <NuxtLink
            class="text-sm no-underline"
            :to="localePath({ name: 'news' })"
          >
            <UiAnimatedUnderlineText>
              {{ $t('homepage.news.seeMore') }}
            </UiAnimatedUnderlineText>
          </NuxtLink>
        </div>
      </div>
    </div>
  </section>
</template>
